<template>
    <div class="securityWall">
        <div>
            <div class="w-full text-center">
                <h3>Enter the passcode</h3>
            </div>
            <div class="w-full text-center">
                <input-code
                    :length="length"
                    :fontSize="40"
                    fontColor="#000000"
                    inputColor="#9ca3af"
                    v-model="code"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import InputCode from 'vue-fake-input';

    export default {
        name      : 'SecurityWall',
        data      : function () {
            return {
                length: 4,
            };
        },
        computed  : {
            code: {
                get() {
                    return this.$store.getters['Global/getAppPasscode'];
                },
                set(value) {
                    this.$store.commit('Global/setAppPasscode', value);
                },
            },
        },
        watch     : {
            code: {
                immediate: true,
                handler  : function (value) {
                    if ((value || '').length === this.length) {
                        this.$router.push('/');
                    }
                },
            },
        },
        components: {
            InputCode,
        },
    };
</script>
<style lang="scss">
    .securityWall {
        @apply fixed flex flex-wrap items-center justify-center h-screen w-screen;
        background-color: #f4e8d0;
        z-index: 99999999;
    }
</style>
